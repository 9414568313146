html, body {
  height: 100%;
  margin: 0;
  width: 100%;
  font-family: system-ui, sans-serif;
  line-height: 1.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fffaee;
  padding: 0 5px;
}

* {
  box-sizing: border-box;
}

form {
  line-height: 1;
}